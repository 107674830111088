import React from "react";
import styles from "./Transaction.module.css";

const Transaction = () => {
  const data = [
    {
      details: "Investment",
      dateAndTime: "26 Feb, 2023 11:37 PM",
      amount: -1000,
    },
  ];
  const formattedNumber = (
    number,
    showSign = true,
    minimumFractionDigits = 0,
    maximumFractionDigits = 2
  ) => {
    const sign = showSign ? (number >= 0 ? "+" : "-") : "";
    const formattedAmount = Math.abs(number).toLocaleString(undefined, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    });
    return sign + formattedAmount;
  };

  return (
    <div className={styles.transaction}>
      <h4 className={styles.title}>Transactions</h4>
      <div className="container">
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <tbody>
              <tr className={styles.row}>
                <th className={styles.heading}>Details</th>
                <th className={styles.heading}>Date & Time</th>
                <th className={[styles.heading, styles.amount].join(" ")}>
                  Amount
                </th>
              </tr>
              {data.map((el, index) => (
                <tr
                  className={[styles.row, styles.valueContainer].join(" ")}
                  key={index}
                >
                  <td className={styles.item}>{el.details}</td>
                  <td className={styles.item}>{el.dateAndTime}</td>
                  <td
                    className={[
                      styles.item,

                      el.amount > 0
                        ? [styles.positiveAmount, styles.amount].join(" ")
                        : [styles.negativeAmount, styles.amount].join(" "),
                    ].join(" ")}
                  >
                    {formattedNumber(el.amount)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default Transaction;
