import React from "react";
import styles from "./Toggler.module.css";

const Toggler = ({ enable, setEnable, id }) => {
  return (
    <div className={styles.wrapper}>
      <input
        checked={enable}
        onChange={() => setEnable((prev) => !prev)}
        className={styles.switchCheckbox}
        id={id}
        type="checkbox"
      />
      <label
        style={{ background: enable ? "#FCD435" : "#B1B1B1" }}
        className={styles.switchLabel}
        htmlFor={id}
      >
        <span className={styles.switchButton} />
      </label>
    </div>
  );
};

export default Toggler;
