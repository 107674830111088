import React from "react";
import styles from "./Heading.module.css";
import { loading } from "../../../images/image";

const Heading = () => {
  return (
    <section className={styles.wrapper}>
      <h3 className={styles.heading}>ID : 83923</h3>
      <div className={styles.spaceBetween}>
        <div className={styles.statusContainer}>
          <p className={styles.text}>INV-76450005</p>
          <p className={styles.status}>Active</p>
        </div>
        <div className={styles.iconContainer}>
          <img src={loading} alt="#" className={styles.icon} />
        </div>
      </div>
    </section>
  );
};

export default Heading;
