import React from "react";
import styles from "./Actions.module.css";
import SingleAction from "./SingleAction";

const Actions = () => {
  const actionData = [
    {
      title: "Pending Rewards",
      value: "105.73",
      inUsd: "2,126.12",
      buttonText: "Claim rewards",
    },
    {
      title: "My Stakes",
      value: "105,73",
      inUsd: "2,126.12",
      buttonText: "UNSTAKE",
    },
  ];
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Actions</h3>
      <div className={styles.boxWrapper}>
        {actionData.map((el, i) => (
          <SingleAction {...el} key={i} />
        ))}
      </div>
    </div>
  );
};

export default Actions;
