import React from "react";
import formatNumber from "../../utils";
import styles from "./Info.module.css";

const Info = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.amountContainer}>
        <div>
          <h4 className={styles.amount}>
            {formatNumber(1000)} <span className={styles.currency}>EUR</span>
          </h4>
          <p className={styles.text}>Invested</p>
        </div>
        <div>
          <h4 className={styles.amount}>157.5</h4>
          <p className={styles.text}>Profit</p>
        </div>
        <div className={styles.totalReturned}>
          <h4 className={styles.amount}>
            {formatNumber(12442)} <span className={styles.currency}>EUR</span>
          </h4>
          <p className={styles.text}>Total Returned (exc. cap)</p>
        </div>
      </div>

      <div className={styles.boxWrapper}>
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Term basis</p>
          <p className={styles.secondaryText}>Daily</p>
        </div>{" "}
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Interest (daily)</p>
          <p className={styles.value}>2.25%</p>
        </div>{" "}
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Ordered date</p>
          <p className={styles.secondaryText}>26 Feb, 2023 11:37 PM</p>
        </div>{" "}
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Term duration</p>
          <p className={styles.value}>1 Week</p>
        </div>
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Total net profit</p>
          <p className={styles.secondaryText}>
            <span className={styles.currency}>EUR</span>{" "}
            <span className={styles.value}> 157.5</span>
          </p>
        </div>
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Payment source</p>
          <p className={styles.secondaryText}>Main Account</p>
        </div>{" "}
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Term start at</p>
          <p className={styles.secondaryText}>26 Feb, 2023 11:37 PM</p>
        </div>
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Daily profit (exc. cap)</p>
          <p className={styles.secondaryText}>
            <span className={styles.currency}>EUR</span>{" "}
            <span className={styles.value}> 22.5</span>
          </p>
        </div>
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Payment reference</p>
          <p className={styles.value}>28713045</p>
        </div>{" "}
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Term end at</p>
          <p className={styles.secondaryText}>05 Mar, 2023 11:38 PM</p>
        </div>
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Adjust profit</p>
          <p className={styles.value}>0 / 7 times</p>
        </div>{" "}
        <div className={styles.spaceBetween}>
          <p className={styles.text}>Investment Status</p>
          <p className={styles.value}>Locked</p>
        </div>
      </div>
    </div>
  );
};

export default Info;
