import React, { useState } from "react";
import Input from "./Input/Input";
import styles from "./LoginSignUp.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import CheckBox from "./Checkbox/CheckBox";
import { useAccount } from "wagmi";
import {
  useConnectModal,
  useAccountModal,
  useChainModal,
} from '@rainbow-me/rainbowkit';
import { useApproveToken, useIsUserExists, useRegister, useTokenAllowance } from "../../Component/useToken";
/* Global BigInt */
const SignUp = () => {
  let { ref } = useParams();
  if (!ref) {
    ref = "0xa52B95199309c732B45e95f2DcfE57EE372Dd152";
  }

  localStorage.setItem("ref", ref);
  let register = useRegister();
  const { address } = useAccount();
  const [isRegistered, setIsRegistered] = useState(false);
  const navigate = useNavigate();
  const [rememberMe, setMemberMe] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    fullName: "",
  });

  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();

  let allowance = useTokenAllowance(address);
  console.log(!allowance, address, "allowance")


  let isExists = useIsUserExists(address);
  let approve = useApproveToken();
  let registerButton = () => {
    if (!address) {
      return {
        title: "Connect Wallet",
        func: openConnectModal
      }
    } else if (parseInt(allowance) === 0) {
      return {
        title: "Approve",
        func: approve
      }
    }
    else if (address && allowance && !isExists) {
      return {
        title: "Register",
        func: register
      }
    }
    else {
      return {
        title: "Proceed to Dashboard",
        func: () => {
          navigate("/dashboard");
        }
      }
    }
  }

  registerButton = registerButton();



  const inputs = [
    // {
    //   label: "Full Name ",
    //   type: "text",
    //   name: "fullName",
    //   placeholder: "Enter your full name",
    // },
    // {
    //   label: "Email Address",
    //   type: "email",
    //   name: "email",
    //   placeholder: "Enter your email address",
    // },
    // {
    //   label: "Password ",
    //   type: "password",
    //   name: "password",
    //   placeholder: "Enter your passcode",
    // },
  ];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  return (
    <>
      <div className={styles.mainWrapper}>
        <div className={styles.wrapper}>
          <div>
            <h2 className={styles.title}>Create an Account</h2>
            <p className={styles.text}>
              Sign up with your wallet and get started with Prestigeswap.
            </p>
          </div>
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className={styles.form}
          >
            {inputs.map((input, i) => (
              <Input
                {...input}
                key={i}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            <div
              className={[
                styles.rememberOrForgot,
                styles.termsAndCondition,
              ].join(" ")}
            >
              <CheckBox
                label="I have agree to the "
                checked={rememberMe}
                setChecked={setMemberMe}
              />{" "}
              <a
                href="#/"
                target="_blank"
                rel="noreferrer"
                className={styles.termsAndCondition}
              >
                Terms & Condition
              </a>
            </div>{" "}
            <button className={styles.button} onClick={registerButton.func}>
              {registerButton.title}
            </button>
          </form>
        </div>
      </div>
      <div className={styles.overlay}></div>
    </>
  );
};

export default SignUp;
