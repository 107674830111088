import React from "react";
import styles from "./styles.module.css";
import SinglePlan from "./SinglePlan";
import {
  cardBg1,
  cardBg2,
  cardBg3,
  cardBg4,
  cardBg5,
  cardBg6,
} from "../../../images/image";

const AllPlan = () => {
  const allPlans = [
    {
      name: "Innovativ",
      monthlyInterest: 10,
      teamWeeks: 6,
      depositAmount: 15000,
      depositType: "Fixed",
      termDuration: 6,
      capitalReturn: "End of Term",
      totalReturn: 60,
      background: cardBg1,
    },
    {
      name: "Konservativ",
      monthlyInterest: 5,
      teamWeeks: 2,
      depositAmount: 5000,
      depositType: "Fixed",
      termDuration: 2,
      capitalReturn: "End of Term",
      totalReturn: 110,
      background: cardBg2,
    },
    {
      name: "Spezialisiert",
      monthlyInterest: 15,
      teamWeeks: 12,
      depositAmount: 1000,
      depositType: "Fixed",
      termDuration: 12,
      capitalReturn: "End of Term",
      totalReturn: 720,
      background: cardBg3,
    },
    {
      name: "Exclusiv",
      monthlyInterest: 12,
      teamWeeks: 6,
      depositAmount: 25000,
      depositType: "Fixed",
      termDuration: 6,
      capitalReturn: "End of Term",
      totalReturn: 72,
      background: cardBg4,
    },
    {
      name: "Progressiv",
      monthlyInterest: 7,
      teamWeeks: 3,
      depositAmount: 5000,
      depositType: "Fixed",
      termDuration: 3,
      capitalReturn: "End of Term",
      totalReturn: 121,
      background: cardBg5,
    },
    {
      name: "Testpaket",
      monthlyInterest: 2.25,
      teamWeeks: 1,
      depositAmount: 1000,
      depositType: "Fixed",
      termDuration: 1,
      capitalReturn: "End of Term",
      totalReturn: 115,
      background: cardBg6,
    },
  ];
  return (
    <div className={styles.wrapper}>
      {allPlans.map((el, i) => (
        <SinglePlan {...el} key={i} />
      ))}
    </div>
  );
};

export default AllPlan;
