import React from "react";
import styles from "./InvestmentPlan.module.css";
import Heading from "../../Component/InvestmentPlan/Heading/Heading";
import AllPlan from "../../Component/InvestmentPlan/AllPlans/AllPlan";

const InvestmentPlan = () => {
  return (
    <div className={styles.wrapper}>
      <Heading />
      <AllPlan />
    </div>
  );
};

export default InvestmentPlan;
