import React, { useEffect, useState } from "react";
import Input from "./Input/Input";
import styles from "./TransferBalance.module.css";
import { useBuynewlevel, useCheckActiveLevels } from "../../useToken";
import { useAccount } from "wagmi";
import { formatEther } from "viem";
const TransferBalance = ({ setPopup }) => {
  const { address } = useAccount();
  const [current, setCurrent] = useState(0);

  let checkActiveLevels = useCheckActiveLevels(address);

  console.log(current, "current")
  let num = formatEther("30000000000000000000");
  const [values, setValues] = useState({
    availableFunds: current, //current Funds
    amountToTransfer: 85000,
  });

  useEffect(() => {
    setCurrent(checkActiveLevels * num);

    setValues({
      ...values,
      availableFunds: checkActiveLevels + 1,
    });
  }, [checkActiveLevels]);


  console.log(checkActiveLevels, "checkActiveLevels")
  let buynewLevel = useBuynewlevel(checkActiveLevels + 1)
  const inputs = [
    {
      label: "New Thunder Level",
      type: "number",
      name: "availableFunds",
      text: "",
    },
  ];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: +e.target.value });
  };

  return (
    <>
      <div className={styles.mainWrapper}>
        <div className={styles.wrapper}>
          <div>
            <h2 className={styles.title}>Upgrade Thunder</h2>
            <p className={styles.text}>
              You can upgrade Thunder Programs to a new level.
            </p>
          </div>
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className={styles.form}
          >
            {inputs.map((input, i) => (
              <Input
                {...input}
                key={i}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            <div className={styles.buttonContainer}>
              <button
                className={[styles.button, styles.transferButton].join(" ")}
                onClick={buynewLevel}
              >
                Upgrade
              </button>
              <button className={styles.button} onClick={() => setPopup(false)}>
                Cancel
              </button>
            </div>
          </form>
        </div>
        <div className={styles.suggestionContainer}>
          <p className={styles.suggestion}>
            The upgrade fees will be deduct from your wallet.
          </p>
        </div>
      </div>
      <div className={styles.overlay} onClick={() => setPopup(false)}></div>
    </>
  );
};

export default TransferBalance;
