import React from "react";
import styles from "./Heading.module.css";

const Heading = () => {
  return (
    <div className={styles.headingContainer}>
      <h2 className={styles.heading}>Investment Plans</h2>
      <p className={styles.text}>
        Investieren Sie in Kryptowährungen mit Vertrauen und profitieren Sie von
        unserem bewährten Staking-Plan
      </p>
      <p className={styles.tagline}>
        Staken Sie Ihre Kryptowährungen und profitieren Sie von unseren
        automatisierten Investitionsstrategien.
      </p>
    </div>
  );
};

export default Heading;
