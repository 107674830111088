import React, { useEffect, useState } from "react";
import styles from "./MakeYourPayment.module.css";
import formatNumber from "../../Component/utils";
import { qrcode } from "../../images/image";
import { Link } from "react-router-dom";
const MakeYourPayment = () => {
  const [timeRemaining, setTimeRemaining] = useState(3600); //

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1);
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  return (
    <div className={styles.makeYourPayment}>
      <h2 className={styles.title}>Make Your Payment</h2>
      <p className={styles.text}>
        Your order <span className={styles.hightlight}> TNX44032942</span> has
        been placed successfully. To complete, please send the exact amount of
        <span className={styles.hightlight}> {formatNumber(10.0)} BTC</span> to
        the address below.
      </p>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <h3 className={styles.heading}>Pay Bitcoin</h3>
          <p className={styles.expireIn}>
            Expire in{" "}
            <span className={styles.highlight}>
              {formatTime(timeRemaining)}
            </span>
          </p>
        </div>

        <div className={styles.mainBox}>
          <img src={qrcode} alt="#" className={styles.qrCode} />
          <h3 className={styles.amount}>
            <span className={styles.highlight}>{formatNumber(10.0)} </span>{" "}
            <span className={styles.currency}>btc</span>
          </h3>
          <h3 className={styles.balance}>
            <span className={styles.highlight}>{formatNumber(219828.53)} </span>{" "}
            eur
          </h3>
          <h4 className={styles.addressText}>Bitcoin Address</h4>
          <p className={styles.address}>
            0x69ca8d3B8C5a76eD23616c955C3c57f8c06899a4
          </p>
          <p className={styles.note}>Note: ERC-20 Network</p>
        </div>
        <div className={styles.bottomBox}>
          <p className={styles.warning}>
            Be aware of that this order will be cancelled, if you send any other
            BTC amount.
          </p>
          <p className={styles.confirmation}>
            Account will credited once we received your payment.
          </p>
        </div>
      </div>{" "}
      <div className={styles.spaceBetween}>
        <button className={styles.cancelOrder}>Cancel Order</button>
        <Link to="/" className={styles.backToDashboard}>
          Back to Dashboard
        </Link>
      </div>
    </div>
  );
};

export default MakeYourPayment;
