import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./Component/Header/Header";
import OurPlans from "./pages/OurPlans/OurPlans";
import LeaderBoard from "./pages/LeaderBoardPage/LeaderBoardPage";
import InvestmentHistoryPage from "./pages/InvestmentHistoryPage/InvestmentHistoryPage";
import MyStakes from "./pages/MyStakes/PlanDetailsPage";
import Login from "./pages/LoginSignUp/Login";
import SignUp from "./pages/LoginSignUp/SignUp";
import MyProfile from "./pages/MyProfile/MyProfile";
import MakeYourPayment from "./pages/MakeYourPayment/MakeYourPayment";
import InvestmentPlan from "./pages/InvestmentPlan/InvestmentPlan";

import '@rainbow-me/rainbowkit/styles.css';
import {
  darkTheme,
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  polygonMumbai
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { ContractProvider } from './Context/useContract'

function App() {
  const location = useLocation();
  const hideComponent = location.pathname === "/login" || "/signup";

  const { chains, publicClient } = configureChains(
    [polygonMumbai],
    [
      publicProvider(),
      jsonRpcProvider({
        rpc: (chain) => {
          if (chain.id === 80001) {
            return {
              http: `https://rpc-mumbai.maticvigil.com`,
            }
          }
      }}),
    ]
  );
  const { connectors } = getDefaultWallets({
    appName: 'Prestigeswap',
    projectId: '531e05c003f482767609be59333e6334',
    chains
  });
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient
  })


  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains} theme={darkTheme()}>
          <ContractProvider>
            <Header />
            {hideComponent && (
              <div className="mainContainer">
                <div className="mainWrapper">
                  {" "}
                  <Routes>
                    <Route path="/dashboard" element={<OurPlans />} />{" "}
                    <Route path="/leaderboard" element={<LeaderBoard />} />
                    <Route path="/mystakes" element={<MyStakes />}></Route>
                    <Route
                      path="/history"
                      element={<InvestmentHistoryPage />}
                    ></Route>
                    <Route path="/profile" element={<MyProfile />}></Route>
                    <Route path="/transaction" element={<MakeYourPayment />}></Route>
                    <Route path="/plans" element={<InvestmentPlan />}></Route>
                  </Routes>
                </div>
              </div>
            )}
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup/:ref" element={<SignUp />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/" element={<SignUp />} />
            </Routes>
          </ContractProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
