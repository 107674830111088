import React, { useState } from "react";

import formatNumber from "../../utils";
import Details from "./Popup/Details";
import styles from "./DataTable.module.css";

const SingleRow = ({
  id,
  plan,
  date,
  item,
  investedAmount,
  receivedAmount,
  status,
}) => {
  const [orderDetails, setOrderDetails] = useState(false);
  return (
    <>
      <tr
        className={[styles.row, styles.valueContainer].join(" ")}
        onClick={() => setOrderDetails(true)}
      >
        <td className={[styles.item, styles.id].join(" ")}>{id}</td>
        <td className={styles.item}>{plan}</td>
        <td className={styles.item}>{date}</td>
        <td className={[styles.item].join(" ")}>
          {formatNumber(investedAmount)}
          <span className={styles.currency}> USD</span>
        </td>
        <td className={styles.item}>
          {formatNumber(receivedAmount)}
          <span className={styles.currency}> USD</span>
        </td>
        <td
          className={[
            styles.item,
            status.toLowerCase() === "active"
              ? styles.active
              : status.toLowerCase() === "pending"
              ? styles.pending
              : status.toLowerCase() === "completed"
              ? styles.completed
              : status.toLowerCase() === "cancelled"
              ? styles.cancelled
              : "",
          ].join(" ")}
        >
          {status}
        </td>
      </tr>
      {orderDetails && <Details setPopup={setOrderDetails} />}
    </>
  );
};

export default SingleRow;
