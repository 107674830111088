import React, { useState } from "react";
import styles from "./Security.module.css";
import Toggler from "./Toggler/Toggler";

const Security = () => {
  const [enableActiveLog, setEnableActiveLog] = useState(true);
  const [enableEmailMe, setEnableEmailMe] = useState(true);
  const [fa2Authetication, setFa2Authentication] = useState(false);
  return (
    <div className={styles.wrapper}>
      <div>
        <h5 className={styles.title}>Settings</h5>
        <p className={styles.text}>
          These settings are helps you keep your account secure.
        </p>
      </div>
      <div className={styles.informationContainer}>
        <div className={styles.info}>
          <div className={styles.box}>
            <p className={styles.subHeading}>Save my Activity Logs</p>
            <p className={styles.infoText}>
              Save your all{" "}
              <span className={styles.highlight}>activity logs</span> including
              unusual activity detected.
            </p>
          </div>
          <Toggler
            id="enableActiveLog"
            enable={enableActiveLog}
            setEnable={setEnableActiveLog}
          />
        </div>
        <div className={styles.info}>
          <div className={styles.box}>
            <p className={styles.subHeading}>
              Email me if encounter unusual activity
            </p>
            <p className={styles.infoText}>
              You will get email notification whenever encounter invalid login
              activity.
            </p>
          </div>
          <Toggler
            id="enableEmail"
            enable={enableEmailMe}
            setEnable={setEnableEmailMe}
          />
        </div>
        <div className={styles.info}>
          <div className={styles.box}>
            <p className={styles.subHeading}>Change Email Address</p>
            <p className={styles.infoText}>
              Update your current email address to new email address.
            </p>
          </div>
          <button className={styles.button}>Change Email</button>
        </div>{" "}
        <div className={styles.info}>
          <div className={styles.box}>
            <p className={styles.subHeading}>Change Password</p>
            <p className={styles.infoText}>
              Set a unique password to protect your account.
            </p>
          </div>
          <div className={styles.changePassword}>
            <p className={styles.infoText}>
              Last changed: <span className={styles.highlight}>N/A</span>{" "}
            </p>
            <button className={styles.button}>Change Password</button>
          </div>
        </div>{" "}
        <div className={styles.info}>
          <div className={styles.box}>
            <div className={styles.authentication}>
              <p className={styles.subHeading}>2FA Authentication</p>
              <span
                className={[
                  styles.disable,
                  fa2Authetication && styles.enable,
                ].join(" ")}
              >
                Disabled
              </span>
            </div>
            <p className={styles.infoText}>
              Secure your account with 2FA security. When it is activated you
              will need to enter not only your password, but also a special code
              using your mobile.
            </p>
          </div>
          {fa2Authetication ? (
            <button
              className={[
                styles.button,
                fa2Authetication && styles.disableButton,
              ].join(" ")}
              onClick={() => setFa2Authentication((prev) => !prev)}
            >
              Disable
            </button>
          ) : (
            <button
              className={styles.button}
              onClick={() => setFa2Authentication((prev) => !prev)}
            >
              Enable
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Security;
