import { useContext, createContext, useEffect, useState } from 'react'
import { useAccount, useContractRead } from "wagmi";
import { abi } from '../config/abi'

const ContractContext = createContext()

export function ContractProvider({ children }) {
  const { address } = useAccount();
  const [level, setLevel] = useState(0);

  const { data: levelPrice } = useContractRead({
    address: "0x73d28677bD2a9954788471EFaeF33F5463D81605",
    abi: abi,
    functionName: "levelPrice",
    args: [level]
  })

  const { data: maxLevel } = useContractRead({
    address: "0x73d28677bD2a9954788471EFaeF33F5463D81605",
    abi: abi,
    functionName: "LAST_LEVEL"
  })

  const { data: userData } = useContractRead({
    address: "0x73d28677bD2a9954788471EFaeF33F5463D81605",
    abi: abi,
    functionName: "users",
    args: [address]
  })

  return <ContractContext.Provider value={{ userData, maxLevel, levelPrice, setLevel, level }}>{children}</ContractContext.Provider>
}

export const useContract = () => useContext(ContractContext)