import React, { useState } from "react";
import styles from "./Profile.module.css";

const Profile = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.verificationContainer}>
        <p className={styles.verificationText}>
          To be compliant and to protect your account, please verify your
          identity by submitting document.
        </p>
        <button className={styles.button}>Start Verification</button>
      </div>

      <div>
        <h5 className={styles.title}>Personal Information</h5>
        <p className={styles.text}>
          Basic info, like your name and address, that you use on our platform.
        </p>
      </div>
      <div className={styles.informationContainer}>
        <div className={styles.info}>
          <p className={styles.infoText}>Full Name</p>
          <p className={styles.infoText}>test</p>
        </div>
        <div className={styles.info}>
          <p className={styles.infoText}>Display Name</p>
          <p className={styles.infoText}>test</p>
        </div>
        <div className={styles.info}>
          <p className={styles.infoText}>Email</p>
          <p className={styles.infoText}>test@test.de</p>
        </div>{" "}
        <div className={styles.info}>
          <p className={styles.infoText}>Phone Number</p>
          <p className={styles.infoText}>95565385</p>
        </div>{" "}
        <div className={styles.info}>
          <p className={styles.infoText}>Telegram</p>
          <p className={styles.infoText}>Not added yet</p>
        </div>{" "}
        <div className={styles.info}>
          <p className={styles.infoText}>Gender</p>
          <p className={styles.infoText}>Not added yet</p>
        </div>
        <div className={styles.info}>
          <p className={styles.infoText}>Date of Birth</p>
          <p className={styles.infoText}>20 Mar, 2000</p>
        </div>{" "}
        <div className={styles.info}>
          <p className={styles.infoText}>Country </p>
          <p className={styles.infoText}>Western Sahara</p>
        </div>{" "}
        <div className={styles.info}>
          <p className={styles.infoText}>Address</p>
          <p className={styles.infoText}>Not added yet</p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
