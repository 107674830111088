import React from "react";
import styles from "./styles.module.css";
import formatNumber from "../../utils";

const SinglePlan = ({
  name,
  monthlyInterest,
  teamWeeks,
  depositAmount,
  depositType,
  termDuration,
  capitalReturn,
  totalReturn,
  background,
}) => {
  return (
    <div className={styles.cardWrapper}>
      <div
        className={styles.card}
        style={{ backgroundImage: `url(${background})` }}
      >
        <h4 className={styles.name}>{name}</h4>
        <div className={styles.box}>
          <div className={styles.topBox}>
            <div className={styles.interestBox}>
              <div className={styles.amountBox}>
                <h5 className={styles.amount}>
                  {formatNumber(monthlyInterest)}%
                </h5>
                <p className={styles.tagline}>Monthly Interest</p>
              </div>
            </div>
            <div className={styles.weekBox}>
              <div className={styles.amountBox}>
                <h5 className={styles.amount}>{teamWeeks}</h5>
                <p className={styles.tagline}>Term Weeks</p>
              </div>
            </div>
          </div>
          <div className={styles.infoContainer}>
            <div className={styles.info}>
              <p className={styles.text}>Deposit Amount</p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                <span className={styles.highlight}>
                  {formatNumber(depositAmount)}
                </span>{" "}
                <span className={styles.currency}>eur</span>
              </p>
            </div>
            <div className={styles.info}>
              <p className={styles.text}>Deposit Type</p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                {depositType}
              </p>
            </div>
            <div className={styles.info}>
              <p className={styles.text}>Term Duration</p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                <span className={styles.highlight}>{termDuration}</span> months
              </p>
            </div>
            <div className={styles.info}>
              <p className={styles.text}>Capital Return </p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                {capitalReturn}
              </p>
            </div>
            <div className={styles.info}>
              <p className={styles.text}>Total Return </p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                <span className={styles.highlight}>{totalReturn}</span>
              </p>
            </div>
          </div>
        </div>
      </div>{" "}
      <button className={styles.button}>Invest Now</button>
    </div>
  );
};

export default SinglePlan;
