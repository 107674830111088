import React from "react";
import Heading from "../../Component/MyStakes/Heading/Heading";
import BackButton from "../../Component/BackButton/BackButton";
import Info from "../../Component/MyStakes/Info/Info";
import Actions from "../../Component/MyStakes/Actions/Actions";
import Transaction from "../../Component/MyStakes/Transaction/Transaction";
import styles from "./styles.module.css";

const MyStakes = () => {
  return (
    <div className={styles.wrapper}>
      <div>
        <BackButton text="Investment" />
        <Heading />
      </div>
      <Info />
      <Actions />
      <Transaction />
    </div>
  );
};

export default MyStakes;
