import React from "react";
import DataTable from "../../Component/InvestmentHistory/DataTable/DataTable";
import BackButton from "../../Component/BackButton/BackButton";
import Heading from "../../Component/InvestmentHistory/Heading/Heading";
import styles from "./InvestmentHistoryPage.module.css";
import { useEffect } from "react";
import { useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";

const InvestmentHistoryPage = () => {
  const navigate = useNavigate();
  const { address } = useAccount();
  useEffect(() => {
    if (!address) {
      navigate("/signup");
    }
  }, []);
  return (
    <div className={styles.wrapper}>
      <div>
        <BackButton text="Investment" />
        <Heading />
      </div>
      <DataTable />
    </div>
  );
};

export default InvestmentHistoryPage;
