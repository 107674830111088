import React from "react";
import styles from "./InvestmentAccount.module.css";
import useTokenBalance from "../../useToken";
import { useAccount } from "wagmi";
const InvestmentAccount = ({ setTransferFunds }) => {
  const { address } = useAccount();
  const useBalance = useTokenBalance(address);
  const formatNumber = (number) =>
    number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  return (
    <div className={styles.investmentAccount}>
      <h4 className={styles.title}>Thunder Account </h4>
      <div className={styles.amountContainer}>
        <div>
          <h4 className={styles.availableAmount}>
            {useBalance} <span className={styles.currency}>USDT</span>{" "}
          </h4>
          <p className={styles.text}>Available Funds</p>
        </div>
        <div>
          <h4 className={styles.lockedAmount}>{formatNumber(8569)}</h4>
          <p className={styles.text}>Total Users</p>
        </div>
      </div>
      <button className={styles.button} onClick={() => setTransferFunds(true)}>
        Upgrade
      </button>
    </div>
  );
};

export default InvestmentAccount;
