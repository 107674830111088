import React from "react";
import styles from "./Heading.module.css";

const Heading = () => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.header}>
        <h3 className={styles.heading}>Thunder Programs</h3>
        {/* <div className={styles.buttonContainer}>
          <button className={[styles.button, styles.sendFunds].join(" ")}>
            Send Funds
          </button>
          <button className={[styles.button, styles.investAndEarn].join(" ")}>
            Invest and Earn
          </button>
          <button className={[styles.button, styles.deposit].join(" ")}>
            Deposit
          </button>
        </div> */}
      </div>
      <p className={styles.text}>Empowering Industries with Blockchain Innovation!</p>
    </section>
  );
};

export default Heading;
