import React from "react";
import Heading from "../../Component/OurPlans/Heading/Heading";
import BackButton from "../../Component/BackButton/BackButton";

import styles from "./styles.module.css";
import InvestmentAccount from "../../Component/OurPlans/InvestmentAccount/InvestmentAccount";
import AmountInvested from "../../Component/OurPlans/AmountInvested/AmountInvested";
import ActivePlan from "../../Component/OurPlans/ActivePlan/ActivePlan";
import Status from "../../Component/OurPlans/Status";
import { useState } from "react";
import TransferBalance from "../../Component/OurPlans/Popup/TransferBalance";
import { useEffect } from "react";
import { useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";
import { useIsUserExists } from "../../Component/useToken";
const OurPlans = () => {
  const navigate = useNavigate();
  const { address } = useAccount();
  const [isRegistered, setIsRegistered] = useState(false);
  useEffect(() => {
    if (!address) {
      navigate("/signup");
    }
  }, []);
  const [transferFunds, setTransferFunds] = useState(false);


  const isExists = useIsUserExists(address);

  useEffect(() => {
    setIsRegistered(isExists)
  }, [isExists]);
  // alert(isExists)
  useEffect(() => {
    // setTimeout(() => {
    //   if (!isRegistered) {
    //     navigate("/signup");
    //   }
    // }, 3000);
  }, [isExists]);
  return (
    <>
      <div className={styles.wrapper}>
        <BackButton text="Investment" />
        <Heading />
        <div className={styles.investmentInfo}>
          <InvestmentAccount setTransferFunds={setTransferFunds} />
          <AmountInvested />
        </div>
        <Status setTransferFunds={setTransferFunds} />
      </div>
      {transferFunds && <TransferBalance setPopup={setTransferFunds} />}
    </>
  );
};

export default OurPlans;
