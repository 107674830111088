import React, { useState } from "react";
import styles from "./ActivePlan.module.css";
import { abi } from "../../../config/abi";
import { useContractReads, useAccount, useContractRead } from "wagmi";
import { formatUnits } from 'ethers';
import { useContract } from '../../../Context/useContract'

const Status = () => {
    const { address } = useAccount();
    const { levelPrice, level, setLevel, userData, maxLevel } = useContract();

    return (
        <section className={styles.wrapper}>
            <h5 className={styles.title}>Status</h5>
            <div className={styles.allPlan}>
                <div className={styles.singlePlan}>
                    <div className={styles.allBoxs}>
                        {userData &&
                            <>
                                <div className={styles.box}>
                                    <p className={styles.text}>{userData[0].toString() === '0' ? 'Not Exist' : userData[0].toString()}</p>
                                    <p className={styles.secondaryText}>User ID</p>
                                </div>
                                <div className={styles.box}>
                                    <p className={styles.text}>{userData[2].toString() === '0' ? 'No Sponsor' : userData[2].toString()}</p>
                                    <p className={styles.secondaryText}>Total Sponsor</p>
                                </div>
                                <div className={styles.box}>
                                    <p className={styles.text}>{userData[1].toString() === '0' ? 'Not Exist' : userData[1].toString()}</p>
                                    <p className={styles.secondaryText}>Sponsored by</p>
                                </div>
                            </>
                        }
                        {/* <div className={styles.buttonContainer}>
                                <button
                                    onClick={() => setTransferFunds(true)}
                                    className={styles.manageButton}>UPGRADE</button>
                            </div> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Status;