import React from "react";
import styles from "./Heading.module.css";

const Heading = () => {
  return (
    <div className={styles.header}>
      <div>
        <h3 className={styles.heading}>Investment History</h3>
        <p className={styles.text}>
          List of your investment plan that you have invested.
        </p>
      </div>
      <button className={[styles.button, styles.deposit].join(" ")}>
        Invest More
      </button>
    </div>
  );
};

export default Heading;
