import React, { useEffect, useState } from "react";
import styles from "./DataTable.module.css";
import axios from "axios";
import {
  weth,
  wbtc,
  aave,
  link,
  crv,
  inch,
  mana,
  comp,
  ocean,
  usdc,
} from "../../images/image";
import SingleRow from "./SingleRow";

const DataTable = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["Overview"];
  let [registrations, setRegistrations] = useState([])


  async function fetchRegistrations() {
    let api = "https://api.thegraph.com/subgraphs/name/rustydev01/corsage";
    let query = `{
      registrations {
        blockTimestamp
        id
        referrer
        referrerId
        transactionHash
        userId
        user
      }
    }`


    let { data } = await axios.post(api, { query });
    console.log(data)
    setRegistrations(data.data.registrations);
  }

  console.log(registrations)

  useEffect(() => {
    fetchRegistrations()
  }, [])

  return (
    <div className={styles.wrapper}>
      <div className={styles.textContainer}>
        <h4 className={styles.title}>Latest Registrations </h4>
        <p className={styles.text}>
          You have full control to manage your own account setting.
        </p>
      </div>
      <div className={styles.tabContainer}>
        {tabs.map((el, i) => (
          <p
            key={i}
            onClick={() => setActiveTab(i)}
            className={[styles.tab, activeTab === i && styles.activeTab].join(
              " "
            )}
          >
            {el}
          </p>
        ))}
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <th className={[styles.heading, styles.textAlignLeft].join(" ")}>
                User
              </th>
              <th className={styles.heading}>Referrer</th>
              <th className={styles.heading}>Hash</th>

            </tr>
            {registrations.map((el, index) => (
              <SingleRow {...el} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTable;
