import React from "react";
import styles from "./DataTable.module.css";

const SingleRow = ({
  blockTimestamp,
  id,
  referrer,
  referrerId,
  transactionHash,
  userId,
  user
}) => {
  const formattedNumber = (
    number,
    showSign = true,
    currency = null,
    minimumFractionDigits = 2
  ) => {
    const sign = showSign ? (number >= 0 ? "+" : "-") : "";

    let formattedAmount = number.toFixed(Math.max(minimumFractionDigits, 2));

    if (minimumFractionDigits > 0) {
      formattedAmount = parseFloat(formattedAmount).toFixed(
        minimumFractionDigits
      );
    }

    if (currency) {
      const currencyIcon = getCurrencyIcon(currency);
      return currencyIcon + sign + formattedAmount;
    } else {
      return sign + formattedAmount;
    }
  };

  const getCurrencyIcon = (currency) => {
    const currencyIcons = {
      USD: "$",
      EUR: "€",
      GBP: "£",
      // Add more currency codes and their respective icons as needed
    };

    return currencyIcons[currency] || "";
  };
  return (
    <>
      <tr className={[styles.row, styles.valueContainer].join(" ")}>
        <td className={styles.item}>
          <div className={styles.info}>
            {/* <img src={"ddd"} alt="#" className={styles.logo} /> */}
            <div className={styles.nameAndInfo}>
              <p className={[styles.name, styles.textAlignLeft].join(" ")}>
                {/* {user.slice(0, 6) + "...." + user.slice(-4)} */}
                {user}
              </p>
              {/* <p className={[styles.info, styles.textAlignLeft]}>
                {"ddd"}
              </p> */}
            </div>
          </div>
        </td>
        <td className={styles.item}>{referrer}</td>
        <td className={styles.item}>{transactionHash}</td>

      </tr>
    </>
  );
};

export default SingleRow;
