import { ethers, formatEther, parseEther } from 'ethers';
import { useEffect, useState } from 'react';
import { useAccount, useContractWrite, useContractRead } from 'wagmi'
import { erc20ABI } from 'wagmi'
import { toast } from 'react-toastify'
import { abi } from "../config/abi"

/* global BigInt */

let provider = new ethers.JsonRpcProvider("https://rpc-mumbai.maticvigil.com");

export default function useTokenBalance(user) {
    const [balance, setBalance] = useState(0);
    useEffect(() => {
        async function getBalance() {
            const token = new ethers.Contract(
                "0x772b6F9aac5356Fb23efb1FCadF203Bfa5BE280E",
                erc20ABI,
                provider
            )
            const balance = await token.balanceOf(user);
            setBalance(formatEther(balance));
        }
        getBalance();
    }, [user]);

    return balance;
}

export function useTokenAllowance(user) {
    const [allowance, setAllowance] = useState(0);
    useEffect(() => {
        async function getAllowance() {
            const token = new ethers.Contract(
                "0x772b6F9aac5356Fb23efb1FCadF203Bfa5BE280E",
                erc20ABI,
                provider
            )

            let allowance = await token.allowance(user, "0x73d28677bD2a9954788471EFaeF33F5463D81605");
            setAllowance(formatEther(allowance));
        }
        getAllowance();
    }, [user]);

    return allowance;
}

export function useMatrixBalance(user) {
    const [balance, setBalance] = useState(0);
    useEffect(() => {
        async function getMatrixBal() {
            const token = new ethers.Contract(
                "0x73d28677bD2a9954788471EFaeF33F5463D81605",
                erc20ABI,
                provider
            )

            let bal = await token.balances(user);
            setBalance(formatEther(bal));
        }
        getMatrixBal();
    }, [user]);

    return balance;
}

export function usePartnerCount(user) {
    const [partnerCount, setPartnerCount] = useState(0);
    useEffect(() => {
        async function getMatrixBal() {
            const token = new ethers.Contract(
                "0x73d28677bD2a9954788471EFaeF33F5463D81605",
                abi,
                provider
            )

            let bal = await token.users(user);
            setPartnerCount(bal.partnersCount);
        }
        getMatrixBal();
    }, [user]);

    return partnerCount;
}

export function useIsUserExists(user) {
    const [isExists, setIsExists] = useState(false);
    useEffect(() => {
        async function getMatrixBal() {
            const token = new ethers.Contract(
                "0x73d28677bD2a9954788471EFaeF33F5463D81605",
                abi,
                provider
            )

            let bal = await token.isUserExists(user);
            setIsExists((bal));
        }
        getMatrixBal();
    }, [user]);

    return isExists;
}


export function useCheckActiveLevels(user) {
    const [isExists, setIsExists] = useState(0);
    useEffect(() => {
        async function CheckActiveLevels() {
            const token = new ethers.Contract(
                "0x73d28677bD2a9954788471EFaeF33F5463D81605",
                abi,
                provider
            )

            for (let i = 1; i <= 6; i++) {
                let bal = await token.usersActiveX3Levels(user, i);
                if (bal) {
                } else {
                    setIsExists(i - 1);
                    break;
                }
            }
        }
        CheckActiveLevels();

    }, [user]);


    return isExists;
}

export function useApproveToken() {
    const { write } = useContractWrite({
        address: "0x772b6F9aac5356Fb23efb1FCadF203Bfa5BE280E",
        abi: erc20ABI,
        functionName: "approve",
        args: ["0x73d28677bD2a9954788471EFaeF33F5463D81605", parseEther("1000000000000000000000")],
        onSuccess(data) {
            console.log("Success", data);
            provider.once(data.hash, (receipt) => {
                toast.success("Transaction Successful", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            })

        },

        onError(error) {
            console.log("Error", error);
            toast.error("an errror occured");
        },
    });
    return write;
}


export function useRegister() {
    const { write } = useContractWrite({
        address: "0x73d28677bD2a9954788471EFaeF33F5463D81605",
        abi: abi,
        functionName: "registrationThunder",
        args: [localStorage.getItem("ref"), "20000000000000000000"],
        onSuccess(data) {
            console.log("Success", data);
            provider.once(data.hash, (receipt) => {
                toast.success("Transaction Successful", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })

        },

        onError(error) {
            console.log("Error", error);
            toast.error("an errror occured");
        },
    });
    return write;
}

export function useBuynewlevel(newLevel) {
    let price;
    let level = Number(newLevel);
    if (level === 1) {
        price = 20;
    } else if (level === 2) {
        price = 40
    } else if (level === 3) {
        price = 80
    } else if (level === 4) {
        price = 160
    } else if (level === 5) {
        price = 320
    } else if (level === 6) {
        price = 640
    } else if (level === 7) {
        price = 1280
    } else if (level === 8) {
        price = 2560
    } else if (level === 9) {
        price = 5120
    } else if (level === 10) {
        price = 10240
    } else {
        price = 0
    }

    let ether = parseEther((price).toString());


    const { write } = useContractWrite({
        address: "0x73d28677bD2a9954788471EFaeF33F5463D81605",
        abi: abi,
        functionName: "upgradeNewLevel",
        args: [newLevel, ether],
        onSuccess(data) {
            console.log("Success", data);
            provider.once(data.hash, (receipt) => {
                toast.success("Transaction Successful", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })

        },

        onError(error) {
            console.log("Error", error);
            toast.error("an errror occured");
        },
    });
    return write;
}
