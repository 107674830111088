import React from "react";
import formatNumber from "../../utils";
import styles from "./AmountInvested.module.css";
import { history, transferCard } from "../../../images/image";
import ChartComponent from "./Chart/Chart";
import { useAccount } from "wagmi";
const AmountInvested = () => {
  const { address } = useAccount();
  return (
    <div className={styles.amountInvested}>
      <div className={styles.wrapper}>
        <div>
          <h4 className={styles.title}>Amount in Invested </h4>
          <div className={styles.amountContainer}>
            <div>
              <h4 className={styles.investedAmount}>
                {formatNumber(1000)}{" "}
                <span className={styles.currency}>EUR</span>{" "}
              </h4>
              <p className={styles.text}>Profits</p>
            </div>
            <div>
              <h4 className={styles.prifitAmount}>+{formatNumber(157.5)} </h4>
              <p className={styles.text}>Approx Profit</p>
            </div>
          </div>
        </div>{" "}
        <ChartComponent />
      </div>

      <div className={styles.bottomBar}>
        <div className={styles.box}>
          <img src={transferCard} alt="#" className={styles.icon} />
          <p
            onClick={() => {
              // copy https://localhost:3000/signup?ref=0x1234567890123456789012345678901234567890 to clipboard
              navigator.clipboard.writeText(
                `https://test.prestigeswap.com/signup/${address}`
              );
              setTimeout(() => {
                alert("Copied to clipboard");
              }, 500);

            }}
            className={styles.bottomText}>Copy Referral Link</p>
        </div>
        {/* <div className={styles.box}>
          <img src={history} alt="#" className={styles.icon} />
          <p className={styles.bottomText}>History</p>
        </div> */}
      </div>
    </div>
  );
};

export default AmountInvested;
