import React from "react";
import { BsArrowUpShort } from "react-icons/bs";
import { useAccount } from "wagmi";
import styles from "./Sidebar.module.css";
import {
  dashboard,
  myStakes,
  transaction,
  plans,
  profile,
  contact,
  activeDashboard,
  activeTransaction,
  activePlans,
  activeProfile,
  leaderBoard,
  activeLeaderBoard,
  investment,
  activeInvestment,
  activeContact,
} from "../../../images/image";
import { NavLink } from "react-router-dom";
import { usePartnerCount } from "../../useToken.js";
import useTokenBalance from "../../useToken.js";


const Sidebar = ({ sidebar, setSidebar }) => {

  const navItems = [
    {
      icons: [dashboard, activeDashboard],
      navItem: "Dashboard",
      to: "/",
    },

    // {
    //   icons: [transaction, activeTransaction],
    //   navItem: "Transactions",
    //   to: "/transaction",
    // },
    {
      icons: [investment, activeInvestment],
      navItem: "History",
      to: "/",
    },
    // {
    //   icons: [myStakes, activeTransaction],
    //   navItem: "My Stakes",
    //   to: "/mystakes",
    // },
    // { icons: [plans, activePlans], navItem: "Our Plans", to: "/plans" },
    {
      icons: [leaderBoard, activeLeaderBoard],
      navItem: "Leaderboard",
      to: "/",
    },
    {
      icons: [leaderBoard, activeLeaderBoard],
      navItem: "Partners",
      to: "/",
    },
    {
      icons: [leaderBoard, activeLeaderBoard],
      navItem: "Stats",
      to: "/",
    },
    {
      icons: [leaderBoard, activeLeaderBoard],
      navItem: "Geneology",
      to: "/",
    },
    // { icons: [profile, activeProfile], navItem: "My Profile", to: "/profile" },
  ];
  const formatNumber = (number) =>
    number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const { address } = useAccount();
  let useBalance = useTokenBalance(address);
  const partnerCount = usePartnerCount(address);

  return (
    <section
      className={[styles.sidebar, sidebar && styles.showSidebar].join(" ")}
    >
      <div className={styles.info}>
        <div className={styles.balanceContainer}>
          <p className={styles.navHeading}>MY USDT BALANCE</p>
          <h4 className={styles.balance}>
            {useBalance}{" "}
            <span className={styles.currency}>USDT</span>
          </h4>
          <p className={styles.inUsd}>{useBalance} USD</p>
        </div>
        <div className={styles.profitDeprsitInfo}>
          <div className={styles.spaceBetween}>
            <div className={styles.key}>
              <p>Partner List </p>
              <p className={styles.profit}>
                <span></span>
              </p>
            </div>
            <p className={styles.value}>
              {partnerCount.toString()}
              <span className={styles.valueCurrency}></span>
            </p>
          </div>
          {/* <div className={styles.spaceBetween}>
            <p className={styles.key}>referral list</p>
            <p className={styles.value}>
              1
              <span className={styles.valueCurrency}></span>
            </p>
          </div> */}
          {/* <div className={styles.spaceBetween}>
            <p className={styles.key}>Withdraw in progress</p>
            <p className={styles.value}>
              {formatNumber(1.0)}{" "}
              <span className={styles.valueCurrency}>Eur</span>
            </p>
          </div> */}
          {/* <div className={styles.buttonContainer}>
            <button className={[styles.button, styles.deposit].join(" ")}>
              Deposit
            </button>
            <button className={[styles.button, styles.withdraw].join(" ")}>
              Withdraw
            </button>
          </div> */}
        </div>
      </div>
      <div className={styles.navContainer}>
        {" "}
        <div>
          <p className={styles.navHeading}>Menu</p>
          <div className={styles.navItems}>
            {navItems.map((el, i) => (
              <NavLink
                to={el.to}
                key={i}
                className={({ isActive }) =>
                  isActive
                    ? [styles.navItem, styles.navActive].join(" ")
                    : styles.navItem
                }
                onClick={() => setSidebar((prev) => !prev)}
              >
                {/* {({ isActive, isPending }) => {
                  isActive ? (
                    <img src={el.icons[0]} alt="#" className={styles.navIcon} />
                  ) : (
                    <img src={el.icons[1]} alt="#" className={styles.navIcon} />
                  )
                }} */}
                {({ isActive, isPending }) => {
                  return (
                    <>
                      <img
                        src={isActive ? el.icons[1] : el.icons[0]}
                        alt="#"
                        className={styles.navIcon}
                      />
                      <span>{el.navItem}</span>
                    </>
                  );
                }}
              </NavLink>
            ))}
          </div>
        </div>
        {/* <div>
          <p className={styles.navHeading}>Additional</p>
          <NavLink
            to="/home"
            className={({ isActive }) =>
              isActive
                ? [styles.navItem, styles.navActive].join(" ")
                : styles.navItem
            }
            onClick={() => setSidebar((prev) => !prev)}
          >
            Go to Home
          </NavLink>
        </div> */}
      </div>{" "}
      {/* <NavLink
        to="/"
        className={({ isActive }) =>
          [
            styles.navItem,
            styles.contactTab,
            isActive && styles.navActive,
          ].join(" ")
        }
        onClick={() => setSidebar((prev) => !prev)}
      >
        {({ isActive, isPending }) => {
          return (
            <>
              <img
                src={isActive ? activeContact : contact}
                alt="#"
                className={styles.navIcon}
              />
              <span>Contact</span>
            </>
          );
        }}
      </NavLink> */}
    </section>
  );
};

export default Sidebar;
